import React from 'react'
import { graphql } from 'gatsby'
import SchoolsListing from '../../component/SchoolsListing'
import Layout from '../../component/Layout'
import DetailPageBody from '../../component/DetailPageBody'
import SEO from '../../component/SEO'
import { AwardTemplateQueryQuery, NodeAwardsFieldsFragment } from '../../types/generated'
import { LanguageCode } from '../../types/LanguageCode'
import { getSeoImage } from '../../utils'

interface Props {
  data: AwardTemplateQueryQuery
}

export const query = graphql`
  query AwardTemplateQuery($id: String!, $nid: Int!, $langcode: String!) {
    ...HeaderMenu
    ...FooterMenu
    ...SocialMenu

    nodeAwards: nodeAwards(id: { eq: $id }) {
      ...NodeAwardsFields
    }

    englishPage: nodeAwards(drupal_internal__nid: { eq: $nid }, langcode: { eq: "en" }) {
      path {
        alias
      }
    }

    spanishPage: nodeAwards(drupal_internal__nid: { eq: $nid }, langcode: { eq: "es" }) {
      path {
        alias
      }
    }

    somaliPage: nodeAwards(drupal_internal__nid: { eq: $nid }, langcode: { eq: "so" }) {
      path {
        alias
      }
    }

    vietnamesePage: nodeAwards(drupal_internal__nid: { eq: $nid }, langcode: { eq: "vi" }) {
      path {
        alias
      }
    }

    filipinoPage: nodeAwards(drupal_internal__nid: { eq: $nid }, langcode: { eq: "fil" }) {
      path {
        alias
      }
    }

    schoolsListing: allNodeSchool(
      filter: {
        relationships: {
          field_awards: {
            elemMatch: { drupal_internal__nid: { eq: $nid }, langcode: { eq: $langcode } }
          }
        }
      }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        ...NodeSchoolFields
      }
    }
  }
`

const Award = ({ data }: Props) => {
  const award = data.nodeAwards
  const schoolsListing = data.schoolsListing.nodes
  const {
    footerMenu,
    headerMenu,
    socialMenu,
    englishPage,
    spanishPage,
    somaliPage,
    vietnamesePage,
    filipinoPage,
  } = data

  const translationPaths = {
    en: englishPage?.path?.alias,
    es: spanishPage?.path?.alias,
    so: somaliPage?.path?.alias,
    vi: vietnamesePage?.path?.alias,
    fil: filipinoPage?.path?.alias,
  }

  const menu = {
    header: headerMenu.nodes,
    footer: footerMenu.nodes,
    social: socialMenu.nodes,
  }

  return (
    <Layout
      menu={menu}
      translationPaths={translationPaths}
      langcode={award?.langcode as LanguageCode}
    >
      <SEO
        title={award?.title}
        description={award?.bodySummary}
        image={getSeoImage(award?.relationships?.field_image)}
        url={award?.path?.alias}
      />
      <DetailPageBody content={award as NodeAwardsFieldsFragment} />
      <SchoolsListing schools={schoolsListing} searchLink={award?.field_search_cta_link} />
    </Layout>
  )
}

export default Award
